.title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-block__texts {
  flex-grow: 2;
  margin-right: 20px;
}

.title-block__langs-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin: 0px -5px;
}

.title-block .selectable-btn {
  display: block;
  padding: 0px 5px;
}

.products__list {
  margin-bottom: 36px;
  margin-top: 12px;
}

.products__col {
  flex: 1 1 25%;
  padding: 0 16px;
}
.products__col:first-child {
  flex-basis: auto;
}
.products__col:last-child {
  flex-basis: auto;
}
.products__img {
  display: block;
  object-fit: contain;
  height: 100px;
  width: 100px;
}
.products__link {
  background-color: #32ac77;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 500;
  margin: auto;
  min-width: 162px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  padding: 5px 15px;
}
.products__fake-link {
  display: none;
}

.products__link:hover {
  background-color: #38bf84;
}
.products__title {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  display: inline-block;
  line-height: 1.45;
  text-decoration: underline;
  cursor: pointer;
}
.products__title:hover {
  color: #f60;
}
.products__price {
  font-size: 24px;
  font-weight: 700;
  margin: 0.3em 0;
}
.products__header {
  border-top: 1px solid #b4b4b4;
  border-bottom: 1px solid #e6e6e6;
  font-size: 14px;
  font-weight: 600;
}
.products__header .products__col:first-child {
  min-width: 100px;
}

.products__header .products__col:last-child {
  min-width: 162px;
}
.products__row {
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
}
.products__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}
.products__loader-overlay {
  background-color: rgba(255,255,255,0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.products__source {
  color: #787878;
  font-size: 14px;
}
.products__error-title {
  font-size: 36px;
}
.products__error-text {
  text-align: center;
}
.products__overlay {
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0.65em 0;
}
.subtitle {
  color: #555;
  margin: 0 0 24px;
}

.header {
  margin: 4px 0px;
  font-size: 20px;
}
.subheader {
  color: #555;
  margin: 4px 0px;
  font-size: 20px;
}

.sorting-block {
  align-items: center;
  display: flex;
  margin-bottom: 1em;
}

.selectable-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  margin: 0 3px;
  padding: 0;
  outline: none;
  line-height: 1;
  text-decoration: underline;
  color: black;
}

.selectable-btn:hover {
  color:#f60;
  text-decoration: underline;
}

.selectable-btn_selected,
.selectable-btn_selected:hover {
  text-decoration: none;
}

.sorting-block__text {
  font-size: 15px;
}

@media(max-width: 767px) {
  .title {
    font-size: 1.5em;
  }

  .sorting-block {
    margin-bottom: 0;
  }

  .products__col {
    padding: 0;
    text-align: center;
  }

  .products__header {
    display: none;
  }

  .products__img {
    margin-bottom: 12px;
  }

  .products__inner {
    flex-direction: column;
    padding: 24px 0;
  }  

  .products__link {
    margin: 12px auto 0;
  }

  .products__row {
    border-left: none;
    border-right: none;
  }  
  
  .products__stars {
    display: none;
  }
}