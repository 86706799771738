.dashboard {
  padding: 16px;
}

.dashboard__inline {
  display: inline-block;
  margin-right: 16px;
}

h4 {
  margin-top: 0px;
}
