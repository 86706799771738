.container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 576px){
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px){
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px){
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px){
  .container {
    max-width: 1140px;
  }
}